<template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <b-alert v-model="showErrorAlert" variant="danger" dismissible>
      {{ messages }}
    </b-alert>
    <b-alert variant="success" v-model="showSuccessAlert" dismissible>
      {{ messages }}
    </b-alert>
    <div class="page-header">
      <h1>{{ $t("CREATION-DEV-MAN") }}</h1>
    </div>
    <form class="body">
      <div class="containerz">
        <div class="form">
          <div class="left">
            <div class="logo" v-if="!form.logo">
              <span class="textstyle" v-if="form.lib">{{ form.lib[0] }}</span>
            </div>
            <div class="logo" v-else>
              <img :src="downloadUrl + form.logo" alt="logo" />
            </div>
            <div
              class="fieldset"
              v-if="isCommercialBatigo || isSuperAdmin"
              v-b-modal.entrepriseModal
            >
              <div class="legend">{{ $t("COMPANY_NAME") + "*" }}</div>
              <div class="input" v-if="form.lib">{{ form.lib }}</div>
              <div class="input placeholder" v-else>
                {{ $t("COMPANY_NAME") }}
              </div>
            </div>
            <div class="fieldset" v-else>
              <div class="legend">{{ $t("COMPANY_NAME") }}</div>
              <div class="input textstyle" v-if="form.lib">{{ form.lib }}</div>
              <div class="input placeholder" v-else>
                {{ $t("COMPANY_NAME") }}
              </div>
            </div>
            <div class="fieldset">
              <div class="legend">{{ $t("ADRESS") }}</div>
              <div class="input textstyle" v-if="form.rue">{{ form.rue }}</div>
              <div class="input placeholder" v-else>{{ $t("ADRESS") }}</div>
            </div>
            <div class="justify">
              <div class="fieldset">
                <div class="legend">{{ $t("POSTAL_CODE") }}</div>
                <div class="input" v-if="form.cp">{{ form.cp }}</div>
                <div class="input placeholder" v-else>
                  {{ $t("POSTAL_CODE") }}
                </div>
              </div>
              <div class="fieldset">
                <div class="legend">{{ $t("CITY") }}</div>
                <div class="input textstyle" v-if="form.ville">
                  {{ form.ville }}
                </div>
                <div class="input placeholder" v-else>{{ $t("CITY") }}</div>
              </div>
            </div>
            <div class="fieldset">
              <div class="legend">{{ $t("EMAIL") }}</div>
              <div class="input textstyle" v-if="form.email">
                {{ form.email }}
              </div>
              <div class="input placeholder" v-else>{{ $t("EMAIL") }}</div>
            </div>
            <div class="fieldset">
              <div class="legend">{{ $t("PHONE_NUMBER") }}</div>
              <div class="input" v-if="form.phone">{{ form.phone.formated_phone }}</div>
              <div class="input placeholder" v-else>
                {{ $t("PHONE_NUMBER") }}
              </div>
            </div>
            <div class="fieldset">
              <div class="legend">{{ $t("SIREN_SIRET") }}</div>
              <div class="input" v-if="form.siret">{{ form.siret }}</div>
              <div class="input placeholder" v-else>
                {{ $t("SIREN_SIRET") }}
              </div>
            </div>
            <div class="fieldset">
              <div class="legend">{{ $t("NUM_TVA") }}</div>
              <div class="input" v-if="form.num_tva">{{ form.num_tva }}</div>
              <div class="input placeholder" v-else>{{ $t("NUM_TVA") }}</div>
            </div>
            <div class="fieldset">
              <div class="legend">{{ $t("TYPE_DEVIS") }}</div>
              <b-form-select
                v-model="devis.prime"
                :options="optionsprime"
                text-field="text"
                value-field="value"
              ></b-form-select>
            </div>
            <div
              class="fieldset"
              @click="openuser(false)"
              v-if="devis.prime !== 'devis_libre'"
            >
              <div class="legend">{{ $t("INTERLOCUTEUR") }}</div>
              <div class="input textstyle" v-if="devis.interlocuteur">
                {{ devis.interlocuteur.prenom }} {{ devis.interlocuteur.nom }}
              </div>
              <div class="input placeholder" v-else>
                {{ $t("INTERLOCUTEUR") }}
              </div>
            </div>

            <div class="fieldset" @click="opendelegataire">
              <div class="legend">{{ $t("DELEGATAIRE") }}</div>
              <div class="input" v-if="devis.delegatairecopie">
                {{ devis.delegatairecopie.nom }}
              </div>
              <div class="input placeholder" v-else>
                {{ $t("DELEGATAIRE") }}
              </div>
            </div>
          </div>

          <b-modal id="entrepriseModal" ref="entrepriseModal" hide-footer>
            <template #modal-header="{ close }">
              <h5>{{ $t("CHOOSE") }} {{ $t("SOCIETY") }}</h5>
              <b-button size="sm" @click="close()">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.028"
                  height="17.028"
                  viewBox="0 0 17.028 17.028"
                >
                  <path
                    id="Icon_material-close"
                    data-name="Icon material-close"
                    d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                    transform="translate(-7.5 -7.5)"
                    fill="#393939"
                  />
                </svg>
              </b-button>
            </template>
            <form @submit.prevent="hideModal('entrepriseModal')">
              <div class="center">
                <b-form-group :label="$t('SOCIETY')">
                  <searchInput
                    :list="getAllentreprises"
                    :loader="getentrepriseLoading"
                    label="lib"
                    :searchFunc="all_entreprises"
                    @searchfilter="selectentreprise($event)"
                  ></searchInput>
                </b-form-group>
              </div>

              <div class="actionModel">
                <b-button variant="success" type="submit">
                  {{ $t("CONFIRM") }}
                </b-button>
              </div>
            </form>
          </b-modal>

          <div class="right">
            <b-form-group :label="$t('DATE_VISITE_TECHNIQUE')">
              <b-form-input
                type="date"
                v-model="devis.visite_technique"
              ></b-form-input>
            </b-form-group>
            <b-form-group :label="$t('START') + '*'">
              <b-form-input
                type="date"
                v-model="devis.debut_devis"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group :label="$t('END') + '*'">
              <b-form-input
                type="date"
                v-model="devis.fin_devis"
                required
              ></b-form-input>
            </b-form-group>
            <div class="fieldset" @click="openuser" v-if="isAssistant">
              <div class="legend">{{ $t("USER") }}</div>
              <div class="input textstyle" v-if="devis.owner">
                {{ devis.owner.nom }} {{ devis.owner.prenom }}
              </div>
              <div class="input placeholder" v-else>
                {{ $t("USER") }}
              </div>
            </div>
            <hr />

            <div class="fieldset" @click="openclient">
              <div class="legend">{{ $t("CLIENT_NAME") + "*" }}</div>
              <div class="input" v-if="form2.nom">
                {{ form2.nom }} {{ form2.prenom }}
              </div>
              <div class="input placeholder" v-else>
                {{ $t("CLIENT_NAME") }}
              </div>
            </div>
            <div class="fieldset" @click="openclient">
              <div class="legend">{{ $t("CLIENT_ADRESS") }}</div>
              <div class="input textstyle" v-if="form2.rue">
                {{ form2.rue }}
              </div>
              <div class="input placeholder" v-else>
                {{ $t("CLIENT_ADRESS") }}
              </div>
            </div>
            <div class="fieldset" @click="openclient">
              <div class="legend">{{ $t("CLIENT_EMAIL") }}</div>
              <div class="input textstyle" v-if="form2.email">
                {{ form2.email }}
              </div>
              <div class="input" v-else-if="form2.email_u">
                {{ form2.email_u }}
              </div>
              <div class="input placeholder" v-else>
                {{ $t("CLIENT_EMAIL") }}
              </div>
            </div>
            <div class="fieldset" @click="openclient">
              <div class="legend">{{ $t("CLIENT_NUM") }}</div>
              <div class="input" v-if="form2.phone">{{ form2.phone.formated_phone }}</div>
              <div class="input placeholder" v-else>{{ $t("CLIENT_NUM") }}</div>
            </div>
            <div class="justify" @click="openclient">
              <div class="fieldset">
                <div class="legend">{{ $t("POSTAL_CODE") }}</div>
                <div class="input" v-if="form2.cp">{{ form2.cp }}</div>
                <div class="input placeholder" v-else>
                  {{ $t("POSTAL_CODE") }}
                </div>
              </div>
              <div class="fieldset" @click="openclient">
                <div class="legend">{{ $t("CITY") }}</div>
                <div class="input textstyle" v-if="form2.ville">
                  {{ form2.ville }}
                </div>
                <div class="input placeholder" v-else>{{ $t("CITY") }}</div>
              </div>
            </div>
            <hr />
            <div class="fieldset">
              <v-checkbox
                style="margin: 0; padding: 0"
                v-model="devis.same_travaux_address"
                :label="`${$t('QUESTION_ADRESSE')}`"
              ></v-checkbox>
            </div>

            <div v-if="!devis.same_travaux_address" style="width: 50%">
              <b-form-group :label="$t('TRAVAUX_RUE')" style="width: 100%">
                <b-form-input
                  v-model="devis.rue_travaux"
                  :placeholder="$t('TRAVAUX_RUE')"
                ></b-form-input>
              </b-form-group>

              <b-form-group :label="$t('TRAVAUX_CP')" style="width: 100%">
                <b-form-input
                  v-model="devis.cp_travaux"
                  :placeholder="$t('TRAVAUX_CP')"
                ></b-form-input>
              </b-form-group>

              <b-form-group :label="$t('TRAVAUX_VILLE')" style="width: 100%">
                <b-form-input
                  v-model="devis.ville_travaux"
                  :placeholder="$t('TRAVAUX_VILLE')"
                ></b-form-input>
              </b-form-group>
            </div>
            <div style="width: 50%" v-else-if="form2.id">
              <p class="address">
                Adresse: {{ form2.rue }}, {{ form2.cp }}, {{ form2.ville }}
              </p>
            </div>
            <hr />
            <div class="fieldset">
              <div class="legend">{{ $t("ESTIMATE_DESC") }}</div>
              <b-form-textarea
                id="textarea-large"
                v-model="devis.description"
                size="lg"
                :placeholder="$t('ESTIMATE_DESC')"
                maxlength="1000"
              ></b-form-textarea>
            </div>
          </div>
          <b-modal id="clientModal" ref="clientModal" hide-footer>
            <template #modal-header="{ close }">
              <h5>{{ $t("CHOOSE") }} {{ $t("CUSTOMER") }}</h5>
              <b-button size="sm" @click="close()">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.028"
                  height="17.028"
                  viewBox="0 0 17.028 17.028"
                >
                  <path
                    id="Icon_material-close"
                    data-name="Icon material-close"
                    d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                    transform="translate(-7.5 -7.5)"
                    fill="#393939"
                  />
                </svg>
              </b-button>
            </template>
            <form @submit.prevent="hideModal('clientModal')">
              <div class="center">
                <b-form-group :label="$t('CUSTOMER')">
                  <searchInput
                    :list="getAllclients"
                    :loader="getclientLoading"
                    label="full_name"
                    :searchFunc="all_clients"
                    @searchfilter="selectclient($event)"
                    modalename="client"
                    :filtre="{
                      entreprise: form.id,
                    }"
                  ></searchInput>
                </b-form-group>
              </div>

              <div class="actionModel">
                <b-button variant="success" type="submit">
                  {{ $t("CONFIRM") }}
                </b-button>
              </div>
            </form>
          </b-modal>

          <b-modal id="delegataireModal" ref="delegataireModal" hide-footer>
            <template #modal-header="{ close }">
              <h5>{{ $t("CHOOSE") }} {{ $t("DELEGATAIRE") }}</h5>
              <b-button size="sm" @click="close()">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.028"
                  height="17.028"
                  viewBox="0 0 17.028 17.028"
                >
                  <path
                    id="Icon_material-close"
                    data-name="Icon material-close"
                    d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                    transform="translate(-7.5 -7.5)"
                    fill="#393939"
                  />
                </svg>
              </b-button>
            </template>
            <form @submit.prevent="hideModal('delegataireModal')">
              <div class="center">
                <b-form-group :label="$t('DELEGATAIRE')">
                  <searchInput
                    :list="getAllDelegataire"
                    :loader="getDelegataireLoading"
                    label="nom"
                    :searchFunc="allDelegataire"
                    @searchfilter="selectdelegataire($event)"
                    :filtre="{
                      entreprise_id: form.id,
                    }"
                  ></searchInput>
                </b-form-group>
              </div>

              <div class="actionModel">
                <b-button variant="success" type="submit">
                  {{ $t("CONFIRM") }}
                </b-button>
              </div>
            </form>
          </b-modal>
          <b-modal id="userModal" ref="userModal" hide-footer>
            <template #modal-header="{ close }">
              <h5>{{ $t("CHOOSE") }} {{ $t("USER") }}</h5>
              <b-button size="sm" @click="close()">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.028"
                  height="17.028"
                  viewBox="0 0 17.028 17.028"
                >
                  <path
                    id="Icon_material-close"
                    data-name="Icon material-close"
                    d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                    transform="translate(-7.5 -7.5)"
                    fill="#393939"
                  />
                </svg>
              </b-button>
            </template>
            <form @submit.prevent="hideModal('userModal')">
              <div class="center">
                <b-form-group :label="$t('USER')">
                  <searchInput
                    :list="getAllUsers"
                    :loader="getOnlineUserLoading"
                    label="full_name"
                    :searchFunc="all_users"
                    @searchfilter="selectuser($event)"
                    :filtre="{
                      entreprise_id: form.id,
                    }"
                  ></searchInput>
                </b-form-group>
              </div>
              <div class="actionModel">
                <b-button variant="success" type="submit">
                  {{ $t("CONFIRM") }}
                </b-button>
              </div>
            </form>
          </b-modal>
        </div>
        <div class="groupe" v-for="(groupes, i) in devis.groupes" :key="i">
          <div class="piece">
            <div class="type">
              <p class="textstyle" v-if="group[i].room.lib">
                {{ group[i].room.lib }}
              </p>
              <p v-else>{{ $t("CHOOSE") }} {{ $t("PIECE_") + "*" }}</p>
              <b-button
                size="sm"
                @click="openpiece(i)"
                v-if="group[i].room.lib != 'Divers'"
              >
                <font-awesome-icon icon="pencil-alt" class="icons" />
              </b-button>
            </div>
            <div class="option">
              <b-button
                size="sm"
                v-if="devis.groupes.length > 1"
                @click="popGroup(i)"
              >
                <font-awesome-icon icon="trash" class="icons" />
              </b-button>
              <b-button
                size="sm"
                @click="closeGroup(i)"
                style="background: #e4261b"
              >
                <font-awesome-icon
                  icon="chevron-down"
                  class="icons"
                  v-if="!showGroup[i].bool"
                />
                <font-awesome-icon icon="chevron-up" class="icons" v-else />
              </b-button>
            </div>
          </div>
          <div class="corps" v-show="showGroup[i].bool">
            <div class="complement">
              <div @click="opentravaux(i)" class="block">
                <b-form-group :label="$t('WORKS') + '*'">
                  <div class="input textstyle" v-if="group[i].work">
                    {{ group[i].work.lib }}
                  </div>
                  <div class="input placeholder" v-else>
                    {{ $t("WORKS") }}
                  </div>
                </b-form-group>
              </div>

              <div
                @click="opensousTraitant(i)"
                class="block"
                v-if="group[i].work"
              >
                <b-form-group :label="$t('SOUS-TRAITANT')">
                  <div class="input textstyle" v-if="group[i].sousTraitant">
                    {{ group[i].sousTraitant.name }}
                  </div>
                  <div class="input placeholder" v-else>
                    {{ $t("SOUS-TRAITANT") }}
                  </div>
                </b-form-group>
              </div>
            </div>
            <div class="table">
              <table>
                <thead>
                  <tr>
                    <td
                      style="background-color: #fff; min-width: 32px"
                      v-if="group[i].room.lib != 'Divers'"
                    ></td>
                    <td
                      style="background-color: #fff; min-width: 32px"
                      v-else
                    ></td>
                    <td style="border-radius: 5px 0px 0px 0px ,width:12%">
                      {{ $t("REF") }}
                    </td>
                    <td style="width: 44%">
                      {{ $t("WORDING") }}/{{ $t("DESC") }}
                    </td>
                    <td style="width: 7%">{{ $t("QTE") }}</td>
                    <td style="width: 9%">{{ $t("UNITEU") }}</td>
                    <td style="width: 9%">{{ $t("PUHT") }}</td>
                    <td class="mintaille">{{ $t("TOTALHT") }}</td>
                    <td class="mintaille">{{ $t("REMISE") }}</td>
                    <td class="mintaille">
                      {{ $t("Avec Remise") }}
                    </td>
                    <td
                      class="mintaille"
                      style="border-radius: 0px 5px 0px 0px"
                    >
                      {{ $t("VAT") }}
                    </td>
                    <td
                      class="action_head"
                      style="display: none; min-width: 32px"
                    ></td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, j) in groupes.produits" :key="j">
                    <td
                      style="min-width: 32px"
                      v-if="group[i].room.lib != 'Divers'"
                    ></td>
                    <td style="min-width: 32px" v-else></td>
                    <td>
                      <b-form-input v-model="item.ref" maxlength="10">{{
                        item.ref
                      }}</b-form-input>
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.lib"
                        oninput="this.value = this.value.toUpperCase()"
                        required
                        maxlength="100"
                      ></b-form-input>
                      <b-form-input
                        v-model="item.desc"
                        maxlength="1000"
                      ></b-form-input>
                    </td>
                    <td>
                      <b-form-input
                        type="number"
                        min="1"
                        @change="updateTTC()"
                        v-model="item.quantite"
                        required
                        @input="controlsize(item.quantite, 8)"
                      ></b-form-input>
                    </td>
                    <td>
                      <b-form-select
                        v-model="item.type_unite"
                        :options="optionstype"
                        text-field="text"
                        value-field="value"
                        required
                      ></b-form-select>
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.unit_price"
                        type="number"
                        step="1"
                        min="0"
                        max="10000000.00"
                        required
                        @input="controlsize(item.unit_price, 8)"
                      ></b-form-input>
                    </td>
                    <td>
                      <b-form-input
                        :value="totalHT(item)"
                        readonly
                      ></b-form-input>
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.remise_value"
                        type="number"
                        step="any"
                        min="0"
                        max="100000"
                        @keyup="recalculerTotal"
                        @change="updateTTC()"
                        :readonly="remise == 'remise_globale'"
                      ></b-form-input>
                      <b-form-select
                        v-model="item.remise_type"
                        :options="optionsRemiseType"
                        :disabled="remise == 'remise_globale'"
                      ></b-form-select>
                    </td>
                    <td>
                      <b-form-input
                        :value="totalAR(item)"
                        readonly
                      ></b-form-input>
                    </td>
                    <td>
                      <b-form-select
                        v-model="item.tva"
                        :options="optionsTVA"
                        required
                      ></b-form-select>
                    </td>
                    <td
                      class="action_col"
                      style="min-width: 28px; max-width: 28px; margin-top: 6px"
                    >
                      <font-awesome-icon
                        icon="times"
                        @click="popProduit(i, j)"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td v-if="group[i].room.lib != 'Divers'">
                      <div
                        class="add"
                        @click="openproduit(i)"
                        :title="$t('ADD') + ' ' + $t('PRODUCT')"
                      >
                        <font-awesome-icon icon="plus" class="icons" />
                      </div>
                    </td>
                    <td v-else>
                      <div
                        class="add"
                        :title="$t('LIGNE_LIBRE')"
                        @click="addJokerProduct(i)"
                      >
                        <font-awesome-icon icon="plus" class="icons" />
                      </div>
                    </td>
                    <td>
                      <div class="detail_pvw"></div>
                    </td>
                    <td>
                      <div class="detail_pvw"></div>
                    </td>
                    <td>
                      <div class="detail_pvw"></div>
                    </td>
                    <td>
                      <div class="detail_pvw"></div>
                    </td>
                    <td>
                      <div class="detail_pvw"></div>
                    </td>
                    <td>
                      <div class="detail_pvw"></div>
                    </td>
                    <td>
                      <div class="detail_pvw"></div>
                    </td>
                    <td>
                      <div class="detail_pvw"></div>
                    </td>
                    <td>
                      <div class="detail_pvw"></div>
                    </td>
                    <td
                      style="min-width: 28px; max-width: 28px"
                      v-if="devis.groupes[i].produits.length"
                    ></td>
                  </tr>
                </tbody>
              </table>

              <b-modal id="produitModal" ref="produitModal" hide-footer>
                <template #modal-header="{ close }">
                  <h5>{{ $t("CHOOSE") }}</h5>

                  <b-button size="sm" @click="close()">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17.028"
                      height="17.028"
                      viewBox="0 0 17.028 17.028"
                    >
                      <path
                        id="Icon_material-close"
                        data-name="Icon material-close"
                        d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                        transform="translate(-7.5 -7.5)"
                        fill="#393939"
                      />
                    </svg>
                  </b-button>
                </template>
                <form @submit.prevent="hideModal('produitModal')">
                  <b-tabs content-class="tabs" justified v-model="tabIndex">
                    <b-tab title="First" active lazy @click="loadProduit">
                      <template #title>
                        <b-spinner
                          type="border"
                          small
                          v-if="getProductLoading"
                        ></b-spinner>
                        {{ $t("TypePrestation.Fourniture") }}
                      </template>
                      <div class="center">
                        <searchInput
                          :list="getAllProducts"
                          :loader="getProductLoading"
                          label="lib"
                          :searchFunc="all_products"
                          @searchfilter="addProduit($event, 'produit')"
                          modalename="article"
                          :filtre="{
                            entreprise: form.id,
                          }"
                        ></searchInput>
                      </div>
                    </b-tab>
                    <b-tab title="Second" lazy @click="loadForfait">
                      <template #title>
                        <b-spinner
                          type="border"
                          small
                          v-if="getforfaitLoading"
                        ></b-spinner>
                        {{ $t("TypePrestation.Forfait") }}
                      </template>
                      <div class="center">
                        <searchInput
                          :list="getAllforfaits"
                          :loader="getforfaitLoading"
                          label="lib"
                          :searchFunc="all_forfaits"
                          :filtre="{
                            entreprise: form.id,
                          }"
                          @searchfilter="addProduit($event, 'forfait')"
                          modalename="Forfait"
                        ></searchInput>
                      </div>
                    </b-tab>
                    <b-tab title="Third" lazy @click="loadMainOeuvre">
                      <template #title>
                        <b-spinner
                          type="border"
                          small
                          v-if="getmainoeuvreLoading"
                        ></b-spinner>
                        {{ $t("TypePrestation.MainOeuvre") }}
                      </template>
                      <div class="center">
                        <searchInput
                          :list="getAllmainoeuvres"
                          :loader="getmainoeuvreLoading"
                          label="lib"
                          :searchFunc="all_mainoeuvres"
                          :filtre="{
                            entreprise: form.id,
                          }"
                          @searchfilter="addProduit($event, 'mainoeuvre')"
                          modalename="MainOeuvre"
                        ></searchInput>
                      </div>
                    </b-tab>
                  </b-tabs>

                  <div class="actionModel">
                    <b-button variant="success" @click="pushProduits(i)">
                      {{ $t("CONFIRM") }}
                    </b-button>
                  </div>
                </form>
              </b-modal>
            </div>
            <p class="sousTraitant" v-if="group[i].sousTraitant">
              <span @click="cleanSousTraitant">
                <b-icon-x-circle></b-icon-x-circle>
              </span>
              Sous-traitant: {{ group[i].sousTraitant.name }}, SIRET:
              {{ group[i].sousTraitant.num_siret }}, Num RGE:
              {{ group[i].sousTraitant.num_rge }}
            </p>
          </div>
        </div>

        <b-modal id="travauxModal" ref="travauxModal" hide-footer>
          <template #modal-header="{ close }">
            <h5>{{ $t("CHOOSE") }} {{ $t("WORK") }}</h5>
            <b-button size="sm" @click="close()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.028"
                height="17.028"
                viewBox="0 0 17.028 17.028"
              >
                <path
                  id="Icon_material-close"
                  data-name="Icon material-close"
                  d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                  transform="translate(-7.5 -7.5)"
                  fill="#393939"
                />
              </svg>
            </b-button>
          </template>
          <form @submit.prevent="hideModal('travauxModal')">
            <div class="center">
              <b-form-group>
                <searchInput
                  :list="getAlltravaux"
                  :loader="getLoadingtravaux"
                  label="lib"
                  :searchFunc="alltravaux"
                  @searchfilter="selecttravaux($event)"
                  modalename="traveaux"
                  :filtre="{ entreprise: form.id }"
                  :with_default="1"
                  :with_default_lib="$t('Afficher les travaux publiques')"
                >
                </searchInput>
              </b-form-group>
            </div>

            <div class="actionModel">
              <b-button variant="success" type="submit">
                {{ $t("CONFIRM") }}
              </b-button>
            </div>
          </form>
        </b-modal>

        <b-modal id="pieceModal" ref="pieceModal" hide-footer>
          <template #modal-header="{ close }">
            <h5>{{ $t("CHOOSE") }} {{ $t("ROOM") }}</h5>
            <b-button size="sm" @click="close()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.028"
                height="17.028"
                viewBox="0 0 17.028 17.028"
              >
                <path
                  id="Icon_material-close"
                  data-name="Icon material-close"
                  d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                  transform="translate(-7.5 -7.5)"
                  fill="#393939"
                />
              </svg>
            </b-button>
          </template>
          <form @submit.prevent="hideModal('pieceModal')">
            <div class="center">
              <b-form-group>
                <searchInput
                  :list="getAllpiece"
                  :loader="getLoadingpiece"
                  label="lib"
                  :searchFunc="allpiece"
                  @searchfilter="selectpiece($event)"
                  modalename="piece"
                  :filtre="{ entreprise: form.id }"
                  :with_default="1"
                  :with_default_lib="$t('Afficher les pieces publiques')"
                >
                </searchInput>
              </b-form-group>
            </div>
            <div class="actionModel">
              <b-button variant="success" type="submit">
                {{ $t("CONFIRM") }}
              </b-button>
            </div>
          </form>
        </b-modal>

        <b-modal id="sousTraitantModal" ref="sousTraitantModal" hide-footer>
          <template #modal-header="{ close }">
            <h5>{{ $t("CHOOSE") }} {{ $t("SOUS-TRAITANT") }}</h5>
            <b-button size="sm" @click="close()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.028"
                height="17.028"
                viewBox="0 0 17.028 17.028"
              >
                <path
                  id="Icon_material-close"
                  data-name="Icon material-close"
                  d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                  transform="translate(-7.5 -7.5)"
                  fill="#393939"
                />
              </svg>
            </b-button>
          </template>
          <form @submit.prevent="hideModal('sousTraitantModal')">
            <div class="center">
              <b-form-group>
                <searchInput
                  :list="getAllsousTraitant"
                  :loader="getLoadingsousTraitant"
                  label="name"
                  :searchFunc="allsousTraitant"
                  @searchfilter="selectSousTraitant($event)"
                  :filtre="{ entreprise: form.id }"
                  :with_default="0"
                >
                </searchInput>
              </b-form-group>
            </div>

            <div class="actionModel">
              <b-button variant="success" type="submit">
                {{ $t("CONFIRM") }}
              </b-button>
            </div>
          </form>
        </b-modal>

        <div
          class="groupe_pvw"
          @click="addpiece()"
          :title="$t('INTERVENTION')"
          v-if="
            (devis.groupes[devis.groupes.length - 1].type_piece_id &&
              devis.groupes[devis.groupes.length - 1].type_traveaux_id &&
              devis.groupes[devis.groupes.length - 1].produits.length > 0) ||
            group[0].room.lib == 'Divers'
          "
        >
          <font-awesome-icon icon="plus" class="mr-2" />
          {{ $t("INTERVENTION") }}
        </div>

        <div
          class="groupe_pvw"
          @click="addJoker()"
          :title="$t('LIGNE_LIBRE')"
          v-if="!DiverExist"
        >
          <font-awesome-icon icon="plus" class="mr-2" />
          <div style="margin-top: 2px">{{ $t("LIGNE_LIBRE") }}</div>
        </div>

        <div class="form" style="border: none">
          <div class="left">
            <div class="fieldset textarea">
              <div class="legend">{{ $t("REMARQUE") }}</div>
              <b-form-textarea
                id="textarea-large"
                v-model="devis.remarque"
                size="lg"
                :placeholder="$t('REMARQUE')"
                maxlength="1000"
              ></b-form-textarea>
            </div>
            <div class="fieldset">
              <div class="legend">{{ $t("COND_REG") }}</div>
              <b-form-textarea
                v-model="devis.conditions_reglements"
                size="lg"
                :placeholder="$t('COND_REG')"
                required
                maxlength="190"
              ></b-form-textarea>
            </div>
            <div class="fieldset">
              <div class="legend">{{ $t("MOD_PAYMENT") }}</div>
              <b-form-select
                v-model="devis.moyens_paiement"
                :options="optionspaiement"
                text-field="text"
                value-field="value"
                required
              ></b-form-select>
            </div>
            <div class="fieldset" v-if="devis.moyens_paiement == 'financement'">
              <div class="legend">{{ $t("ORG_PRETEUR") }}</div>
              <b-form-input
                v-model="devis.organisme_preteur"
                type="text"
                :placeholder="$t('ORG_PRETEUR')"
                class="input placeholder"
                maxlength="100"
              ></b-form-input>
            </div>
            <div class="fieldset" v-if="devis.moyens_paiement == 'financement'">
              <div class="legend">{{ $t("MONTANT_CREDIT") }}</div>
              <b-form-input
                v-model="devis.montant_credit"
                type="number"
                min="0"
                max="10000000"
                :placeholder="$t('MONTANT_CREDIT')"
                class="input placeholder"
                @input="controlsize(devis.montant_credit, 8)"
              ></b-form-input>
            </div>
            <div class="fieldset" v-if="devis.moyens_paiement == 'financement'">
              <div class="legend">{{ $t("TEAUX_NOMINAL") }}</div>
              <b-form-input
                v-model="devis.taux_nominal"
                type="number"
                min="0"
                max="10000000"
                :placeholder="$t('TEAUX_NOMINAL')"
                class="input placeholder"
                @input="controlsize(devis.taux_nominal, 10)"
              ></b-form-input>
            </div>
            <div class="fieldset" v-if="devis.moyens_paiement == 'financement'">
              <div class="legend">{{ $t("MENSUALITE") }}</div>
              <b-form-input
                v-model="devis.mensualite"
                type="number"
                min="0"
                max="10000000"
                :placeholder="$t('MENSUALITE')"
                class="input placeholder"
                @input="controlsize(devis.mensualite, 8)"
              ></b-form-input>
            </div>
            <div class="fieldset" v-if="devis.moyens_paiement == 'financement'">
              <div class="legend">{{ $t("NBR_MENSUALITE") }}</div>
              <b-form-input
                v-model="devis.nombre_mensualites"
                type="number"
                min="0"
                max="10000000"
                :placeholder="$t('NBR_MENSUALITE')"
                class="input placeholder"
                @input="controlsize(devis.nombre_mensualites, 8)"
              ></b-form-input>
            </div>
            <div class="fieldset" v-if="devis.moyens_paiement == 'financement'">
              <div class="legend">{{ $t("TAEG") }}</div>
              <b-form-input
                v-model="devis.taeg"
                type="number"
                min="0"
                max="10000000"
                :placeholder="$t('TAEG')"
                class="input placeholder"
                @input="controlsize(devis.taeg, 8)"
              ></b-form-input>
            </div>
            <div class="fieldset" v-if="devis.moyens_paiement == 'financement'">
              <div class="legend">{{ $t("CRIDIT_TOTALE") }}</div>
              <b-form-input
                v-model="devis.cout_total_credit"
                type="number"
                min="0"
                max="10000000"
                :placeholder="$t('CRIDIT_TOTALE')"
                class="input placeholder"
                @input="controlsize(devis.cout_total_credit, 8)"
              ></b-form-input>
            </div>

            <div class="fieldset" v-if="devis.moyens_paiement == 'financement'">
              <div class="legend">{{ $t("ASSURANCE_TYPE") }}</div>
              <div class="radio">
                <b-form-radio
                  v-model="devis.assurance"
                  name="some-radios"
                  :value="true"
                  >{{ $t("AVECASSURANCE") }}</b-form-radio
                >

                <b-form-radio
                  v-model="devis.assurance"
                  name="some-radios"
                  :value="false"
                  >{{ $t("SANSASSURANCE") }}</b-form-radio
                >
              </div>
            </div>
            <div class="fieldset" v-if="devis.assurance">
              <div class="legend">{{ $t("TYPE_ASSURANCE") }}</div>
              <b-form-select
                v-model="devis.assurance_type"
                :options="optiontypeassurance"
                text-field="text"
                value-field="value"
              ></b-form-select>
            </div>
          </div>
          <div class="right">
            <div class="subtable">
              <table class="complet">
                <tr>
                  <td class="thead">
                    {{ $t("TOTAL_HT") }}
                  </td>
                  <td style="border-radius: 0px 5px 0px 0px">
                    {{ prixHT() | financial }}
                  </td>
                </tr>
                <tr>
                  <td class="thead">
                    <b-form-select
                      class="select"
                      v-model="remise"
                      :options="optionsRemise"
                      @change="typeRemise"
                    ></b-form-select>
                  </td>
                  <td v-if="remise == 'remise'">
                    {{ devis.remise_value | financial }}
                  </td>
                  <td v-else>
                    <div class="remise_block">
                      <b-form-input
                        v-model="devis.remise_value"
                        type="number"
                        class="input"
                        max="100000"
                        step="any"
                        @keyup="recalculerTotal"
                        @change="updateTTC()"
                      ></b-form-input>
                      <b-form-select
                        v-model="devis.remise_type"
                        :options="optionsRemiseType"
                        required
                        @change="typeRemise"
                      ></b-form-select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="thead">
                    {{ $t("TOTALREMISE") }}
                  </td>
                  <td>
                    {{ prixAR() | financial }}
                  </td>
                </tr>
                <template v-if="TVA.length">
                  <tr v-for="tva in TVA" :key="'tva' + tva.tva">
                    <td class="thead">TVA ({{ tva.tva }} % )</td>
                    <td>
                      {{ tva.value | financial }}
                    </td>
                  </tr>
                </template>
                <tr>
                  <td class="thead">
                    {{ $t("Total TTC") }}
                  </td>
                  <td>
                    {{ prixTTC() | financial }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <b-modal
        id="part2facture"
        ref="part2facture"
        content-class="shadow"
        :title="$t('Aides supplémentaires')"
        hide-footer
        size="lg"
      >
        <template>
          <form @submit.prevent="reponsequestion">
            <div class="center">
              <b-form-group :label="$t('QUESTION_AIDE')">
                <b-form-select
                  v-model="devis.with_aide"
                  :options="option_with_aide"
                  text-field="text"
                  value-field="value"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                :label="$t('MA_PRIME_RENOV')"
                v-if="devis.with_aide == true"
              >
                <b-form-input
                  v-model.number="devis.montant_maprimrenov"
                  type="number"
                  step="0.01"
                  min="0"
                  max="10000000"
                  :placeholder="$t('NBR_MENSUALITE')"
                  @input="controlsize(devis.montant_maprimrenov, 8)"
                  :required="!devis.montant_cee"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                :label="$t('MONTANT_CEE')"
                v-if="devis.with_aide == true"
              >
                <b-form-input
                  v-model.number="devis.montant_cee"
                  type="number"
                  step="0.01"
                  min="0"
                  max="10000000"
                  :placeholder="$t('MONTANT_CEE')"
                  class="input placeholder"
                  :required="!devis.montant_maprimrenov"
                  @input="controlsize(devis.montant_cee, 8)"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                :label="$t('BaremeMPR')"
                v-if="devis.with_aide == true"
              >
                <b-form-select
                  v-model="devis.bareme_mpr"
                  :options="Barememproption"
                  text-field="text"
                  value-field="value"
                  :required="+devis.montant_maprimrenov > 0"
                ></b-form-select>
              </b-form-group>
            </div>
            <div class="double">
              <b-button variant="danger" @click="hideModal('part2facture')">
                <div class="block-spinner">
                  {{ $t("CANCEL") }}
                </div>
              </b-button>
              <button type="submit" class="btn btn-success">
                {{ $t("CONFIRM") }}
              </button>
            </div>
          </form>
        </template>
      </b-modal>

      <b-modal
        id="cancel"
        ref="cancel"
        centered
        content-class="shadow"
        :title="$t('DRAFT')"
      >
        <template #modal-footer>
          <div class="double">
            <b-button variant="danger" @click="clearAll">
              <div class="block-spinner">
                {{ $t("CANCEL_DEF") }}
              </div>
            </b-button>
            <b-button variant="success" @click="saveBrouillon">
              <div class="block-spinner">
                {{ $t("CREATE") }} {{ $t("DRAFT") }}
              </div>
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-modal
        id="success"
        ref="success"
        centered
        content-class="shadow"
        :title="$t('ESTIMATE')"
      >
        <template #modal-footer>
          <div class="double">
            <b-button variant="danger" @click="openmodelpart">
              <div class="block-spinner">
                {{ $t("CANCEL") }}
              </div>
            </b-button>
            <b-button variant="success" @click="saveBrouillon">
              <div class="block-spinner">
                {{ $t("CREATE") }} {{ $t("DRAFT") }}
              </div>
            </b-button>
            <b-button variant="success" @click="genererDevis(false)">
              <div class="block-spinner">
                {{ $t("GENERATE") }} {{ $t("ESTIMATE") }}
                <div v-if="loading" class="ml-2">
                  <div class="spinner-border" role="status"></div>
                </div>
              </div>
            </b-button>
          </div>
        </template>
      </b-modal>

      <div class="d-flex justify-content-around mt-5 submit">
        <b-button variant="danger" v-b-modal.cancel :title="$t('ANNULER')">
          {{ $t("ANNULER") }}
        </b-button>
        <b-button
          variant="success"
          @click="openmodelpart"
          :title="$t('CHOOSE') + ' ' + $t('PRODUCT')"
          class="d-flex justify-content-center align-items-center"
        >
          {{ $t("GENERATE") }} {{ $t("ESTIMATE") }}
          <div v-if="getdevisLoading" class="ml-2">
            <div class="spinner-border" role="status"></div>
          </div>
        </b-button>
      </div>
    </form>
    <CommentMarche to="/how-it-work/devis"></CommentMarche>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { domains } from "@/environment";
import searchInput from "@/components/ui/searchInput";
import formatMoney from "accounting-js/lib/formatMoney.js";
import CommentMarche from "../components/commentMarche.vue";

export default {
  components: {
    searchInput,
    CommentMarche,
  },

  data() {
    return {
      
      newpiece: {
        lib: null,
        lang: "fr",
        categorie: null,
        favoris: null,
        galerie: null,
      },
      isOwner: true,
      downloadUrl: domains.download,
      search: "",
      page: 1,
      per_page: 10,
      perpageselect: 1000,
      form: {},
      form2: {
        nom: null,
      },
      currentGroup: {
        produit_id: null,
        type_piece_id: null,
        piece: null,
        type_traveaux_id: null,
        travaux: null,
        type_piece: null,
        type_traveaux: null,
        produits: [],
        produit: {
          lib: null,
          unit_price: null,
          img: null,
          quantite: 1,
          desc: null,
        },
      },

      devis: {
        remise: 0,
        remise_type: "remise.HT",
        conditions_reglements: null,
        description: null,
        remarque: null,
        moyens_paiement: "cheque_virement_espece",
        prime: "renovation_globale",
        organisme_preteur: null,
        montant_credit: null,
        taux_nominal: null,
        mensualite: null,
        nombre_mensualites: null,
        taeg: null,
        cout_total_credit: null,
        assurance: false,
        assurance_type: null,
        with_aide: false,
        montant_maprimrenov: null,
        montant_cee: null,
        owner: null,
        interlocuteur: null,
        lang: "fr",
        client_id: null,
        modele_id: 14,
        debut_devis: null,
        fin_devis: null,
        visite_technique: null,
        delegatairecopie: null,
        bareme_mpr: null,
        cp_travaux: null,
        ville_travaux: null,
        rue_travaux: null,
        groupes: [
          {
            produits: [],
          },
        ],
      },
      groupes: [
        {
          produits: [],
        },
      ],
      group: [
        {
          room: {
            lib: null,
          },
          work: null,
        },
      ],
      remise: "remise",
      loading: false,
      save: 0,
      messages: "Veuillez remplir tout les champs correctement !",
      showErrorAlert: false,
      showSuccessAlert: false,
      showGroup: [
        {
          bool: true,
        },
      ],
      Barememproption: [
        { value: null, text: "Aucun" },
        { value: "blue", text: this.$t("BLEU") },
        { value: "yellow", text: this.$t("YELLOW") },
        { value: "purple", text: this.$t("PURPLE") },
        { value: "pink", text: this.$t("PINK") },
      ],
      optiontypeassurance: [
        { value: "deces_invalidite", text: this.$t("DECES_INVALIDITE") },
        { value: "perte_emploi", text: this.$t("PERTE_EMPLOI") },
        {
          value: "deces_invalidite_perte_emploi",
          text: this.$t("DECES_INVALIDITE_PERTE_EMPLOI"),
        },
      ],
      optionsprime: [
        { value: "renovation_globale", text: this.$t("renovation_globale") },
        {
          value: "renovation_energetique",
          text: this.$t("renovation_energetique"),
        },
        { value: "devis_pv", text: this.$t("devis_pv") },
        { value: "devis_libre", text: this.$t("devis_libre") },
      ],
      optionspaiement: [
        {
          value: "cheque_virement_espece",
          text: this.$t("Cheque_virement_espece"),
        },
        { value: "financement", text: this.$t("FINANCEMENT") },
      ],
      option_with_aide: [
        { value: true, text: this.$t("Oui") },
        { value: false, text: this.$t("Non") },
      ],
      optionstype: [
        { value: "m2", text: this.$t("m2") },
        { value: "ml", text: this.$t("ML") },
        { value: "h", text: this.$t("h") },
        { value: "forfait", text: this.$t("FORFAIT") },
        { value: "unitaire", text: this.$t("unitaire") },
      ],
      optionsTVA: [
        { value: 0, text: "0%" },
        { value: 5.5, text: "5.5%" },
        { value: 10, text: "10%" },
        { value: 20, text: "20%" },
      ],
      optionsRemiseType: [
        { value: "remise.HT", text: "€" },
        { value: "remise.pourcentage", text: "%" },
      ],
      optionsRemise: [
        { value: "remise", text: this.$t("REMISE_LIGNE") },
        { value: "remise_globale", text: this.$t("REMISE_GLOBALE") },
      ],
      quit: true,
      tabIndex: 0,
    };
  },
  methods: {
    ...mapActions([
      "all_mainoeuvres",
      "all_forfaits",
      "all_products",
      "allpiece",
      "allprestation",
      "alltravaux",
      "entreprise",
      "store_devis",
      "all_clients",
      "all_entreprises",
      "all_users",
      "allDelegataire",
      "pays",
      "allgallery",
      "client",
      "allsousTraitant",
    ]),
    customLabel({ title }) {
      return `${title}`;
    },

    controlsize(word, size) {
      if (word.length > size) {
        this.messages = "Vous avez dépassé la taille maximale de ce champs.";
        this.showErrorAlert = true;
      } else {
        this.showErrorAlert = false;
      }
    },
    hideModal(ref) {
      this.$refs[ref].hide();
    },
    selectdelegataire(delegataire) {
      this.hideModal("delegataireModal");
      this.quit = false;
      this.devis.delegatairecopie = { ...delegataire };
    },
    selectuser(user) {
      this.hideModal("userModal");
      this.quit = false;
      if (this.isOwner) {
        this.devis.owner = { ...user };
      } else {
        this.devis.interlocuteur = { ...user };
      }
    },
    check(event) {
      this.resultsExample.tel = event.e164;
      this.resultsExample.valid = event.isValid;
    },

    openclient() {
      this.all_clients({
        entreprise_id: this.form
          ? this.form.id
          : this.getOnlineUser.entreprise.id,
        page: this.page,
        per_page: this.perpageselect,
      }).then(() => {
        this.$refs["clientModal"].show();
      });
    },

    openuser(isOwner = true) {
      this.all_users({
        entreprise_id: this.form
          ? this.form.id
          : this.getOnlineUser.entreprise.id,
        page: this.page,
        per_page: this.perpageselect,
      }).then(() => {
        this.isOwner = isOwner;
        this.$refs["userModal"].show();
      });
    },
    opendelegataire() {
      this.allDelegataire({
        page: this.page,
        per_page: this.perpageselect,
        entreprise_id: this.form.id,
      }).then(() => {
        this.$refs["delegataireModal"].show();
      });
    },

    clearAll() {
      this.$router.push("/devis");
    },

    saveBrouillon() {
      this.hideModal("cancel");
      this.hideModal("success");
      this.genererDevis(true);
    },

    selectclient(client) {
      this.hideModal("clientModal");
      this.quit = false;
      this.form2 = { ...client };
    },

    resetclient() {
      this.form2 = null;
      this.form2 = {
        nom: null,
      };
    },
    resetdevis() {
      this.devis = {
        remise: 0,
        owner: null,
        lang: "fr",
        client_id: null,
        modele_id: 14,
        debut_devis: null,
        fin_devis: null,
        groupes: [
          {
            produits: [],
          },
        ],
      };

      var today = new Date();
      var tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);

      this.devis.fin_devis = tomorrow.toLocaleDateString("fr-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      this.devis.debut_devis = this.devis.fin_devis;
      this.groupes = [
        {
          produits: [],
        },
      ];

      this.group = [
        {
          room: {
            lib: null,
          },
          work: null,
        },
      ];
    },

    selectentreprise(ent) {
      this.hideModal("entrepriseModal");
      this.quit = false;
      if (this.form.id != ent.id) {
        this.resetclient();
        this.resetdevis();
      }
      this.form = { ...ent };
    },

    openpiece(i) {
      this.allpiece({
        entreprise: this.form.id
          ? this.form.id
          : this.getOnlineUser.entreprise.id,
        page: this.page,
        per_page: this.perpageselect,
      }).then(() => {
        this.saveGroup(i);
        this.$refs["pieceModal"].show();
      });
    },

    opentravaux(i) {
      this.alltravaux({
        entreprise: this.form.id
          ? this.form.id
          : this.getOnlineUser.entreprise.id,
        page: this.page,
        per_page: this.perpageselect,
      }).then(() => {
        this.saveGroup(i);
        this.$refs["travauxModal"].show();
      });
    },

    opensousTraitant(i) {
      this.allsousTraitant({
        entreprise: this.form.id
          ? this.form.id
          : this.getOnlineUser.entreprise.id,
        page: this.page,
        per_page: this.perpageselect,
      }).then(() => {
        this.saveGroup(i);
        this.$refs["sousTraitantModal"].show();
      });
    },

    creationpossible() {
      var resultat = 0;
      this.devis.groupes.forEach((groupe) => {
        if (groupe.produits.length == 0) {
          this.messages = "Veuillez ajouter un article";
          this.showErrorAlert = true;
          resultat = false;
        } else {
          resultat = true;
        }
      });
      return resultat;
    },
    addJokerProduct(i) {
      var produit = {
        lib: null,
        desc: "",
        ref: "",
        tva: 0,
        remise_value: 0,
        remise_type: "remise.HT",
        quantite: 1,
        unit_price: null,
        type_unite: "unitaire",
        type_ligne: "joker",
      };

      var find = this.devis.groupes[i].produits.findIndex(
        (item) => item.type_ligne == "joker" && (!item.lib || !item.unit_price)
      );

      if (find < 0) this.devis.groupes[i].produits.push(produit);
      else {
        alert("completez correctement le produit precedant (lib, unit_price)");
      }
    },

    jokerBool(i) {
      this.devis.groupes[i].produits.forEach((product) => {
        if (product.type_ligne == "joker") {
          if (!product.lib) return false;
        }
      });

      return true;
    },

    loadProduit() {
      const data = {
        page: this.page,
        per_page: this.perpageselect,
        entreprise: this.form.id
          ? this.form.id
          : this.getOnlineUser.entreprise.id,
      };
      this.all_products(data);
    },

    loadForfait() {
      const data = {
        page: this.page,
        per_page: this.perpageselect,
        entreprise: this.form.id
          ? this.form.id
          : this.getOnlineUser.entreprise.id,
      };
      this.all_forfaits(data);
    },

    loadMainOeuvre() {
      const data = {
        page: this.page,
        per_page: this.perpageselect,
        entreprise: this.form.id
          ? this.form.id
          : this.getOnlineUser.entreprise.id,
      };
      this.all_mainoeuvres(data);
    },

    openproduit(i) {
      const data = {
        page: this.page,
        per_page: this.perpageselect,
        entreprise: this.form.id
          ? this.form.id
          : this.getOnlineUser.entreprise.id,
      };
      this.saveGroup(i);
      this.$refs["produitModal"][i].show();
      this.all_products(data);
    },
    selectpiece(piece) {
      this.hideModal("pieceModal");
      this.quit = false;
      this.group[this.save].room = piece;
      this.devis.groupes[this.save].type_piece_id = piece.id;
      this.devis.groupes[this.save].piece = piece.lib;
    },

    selecttravaux(travaux) {
      this.hideModal("travauxModal");
      this.quit = false;
      this.group[this.save].work = travaux;
      this.devis.groupes[this.save].type_traveaux_id = travaux.id;
      this.devis.groupes[this.save].traveaux = travaux.lib;
    },

    selectSousTraitant(sousTraitant) {
      this.hideModal("sousTraitantModal");
      this.quit = false;
      this.group[this.save].sousTraitant = sousTraitant;
      this.devis.groupes[this.save].sous_traitant_id = sousTraitant.id;
      this.devis.groupes[this.save].sousTraitant = sousTraitant.name;
      this.$forceUpdate();
    },

    cleanSousTraitant() {
      this.group[this.save].sousTraitant = null;
      this.$forceUpdate();
    },

    totalHT(item) {
      const totalSansRemise = item.unit_price * item.quantite;
      if (Math.round(totalSansRemise * 100) / 100 < 0) return 0;
      return Math.round(totalSansRemise * 100) / 100;
    },

    totalAR(item) {
      const totalSansRemise = this.totalHT(item);
      var totalAvecRemise = totalSansRemise;
      if (item.remise_type == "remise.HT")
        totalAvecRemise -= item.remise_value ? +item.remise_value : 0;
      else totalAvecRemise -= (item.remise_value * totalSansRemise) / 100;

      if (totalAvecRemise < 0) return 0;
      return Math.round(totalAvecRemise * 100) / 100;
    },

    recalculerTotal() {
      this.$forceUpdate();
    },

    prixHT() {
      var ht = 0;
      this.devis.groupes.forEach((groupe) => {
        groupe.produits.forEach((product) => {
          ht += this.totalHT(product);
        });
      });
      return Math.round(ht * 100) / 100;
    },

    prixAR() {
      var ht = 0;

      this.devis.groupes.forEach((groupe) => {
        groupe.produits.forEach((product) => {
          ht += this.totalAR(product);
        });
      });
      if (this.remise != "remise") {
        if (this.devis.remise_type == "remise.HT")
          ht -= this.devis.remise_value;
        else ht -= (this.devis.remise_value * ht) / 100;
      }
      if (ht < 0) return 0;

      return Math.round(ht * 100) / 100;
    },

    prixTTC() {
      let tva = 0;
      let totalRemise = 0;
      this.devis.groupes.forEach((groupe) => {
        groupe.produits.forEach((product) => {
          let ht = this.totalHT(product);
          if (product.remise_type == "remise.HT")
            totalRemise += product.remise_value ? +product.remise_value : 0;
          else {
            totalRemise += (product.remise_value * ht) / 100;
          }
        });
      });

      this.TVA.forEach((item) => {
        tva += item.value;
      });

      const totalSansRemise = this.prixHT();
      if (this.remise == "remise") this.devis.remise_value = totalRemise;
      else this.devis.remise_value = +this.devis.remise_value + totalRemise;

      let totalAvecRemise = totalSansRemise;

      if (this.remise == "remise") totalAvecRemise -= this.devis.remise_value;
      else {
        if (this.devis.remise_type == "remise.HT")
          totalAvecRemise -= this.devis.remise_value;
        else
          totalAvecRemise -= (totalAvecRemise * this.devis.remise_value) / 100;
      }

      totalAvecRemise += tva;
      if (Math.round(totalAvecRemise * 100) / 100 < 0) return 0;
      return Math.round(totalAvecRemise * 100) / 100;
    },

    typeRemise() {
      this.devis.remise_value = 0;
      this.devis.groupes.forEach((groupe) => {
        groupe.produits.forEach((product) => {
          product.remise_value = 0;
        });
      });
      this.$forceUpdate();
    },

    pushProduits() {
      var produit = {};
      var find = this.devis.groupes[this.save].produits.findIndex(
        (item) => item.id == this.currentGroup.produit.id
      );
      if (find < 0) {
        if (!this.currentGroup.produit.quantite)
          this.currentGroup.produit.quantite = 1;

        this.currentGroup.produit.remise_value = 0;
        this.currentGroup.produit.remise_type = "remise.HT";
        this.devis.groupes[this.save].produits.push({
          ...this.currentGroup.produit,
        });
      } else {
        produit = this.devis.groupes[this.save].produits[find];
        this.devis.groupes[this.save].produits.splice(find, 1);
        produit.quantite++;
        this.devis.groupes[this.save].produits.splice(find, 0, produit);
      }

      for (var j = 0; j < this.devis.groupes.length; j++) {
        this.$refs["produitModal"][j].hide();
      }
    },

    popProduit(i, j) {
      this.devis.groupes[i].produits.splice(j, 1);
      this.prixTTC();
      this.updateTTC();
      this.recalculerTotal();
    },

    popGroup(i) {
      this.group.splice(i, 1);
      this.devis.groupes.splice(i, 1);
    },

    closeGroup(i) {
      this.showGroup[i].bool = !this.showGroup[i].bool;
    },

    copyProduit(i, j) {
      var copy = this.devis.groupes[i].produits[j];
      this.devis.groupes[i].produits.push(copy);
    },
    addProduit(item, type) {
      if (
        this.currentGroup.produit.lib == null ||
        this.currentGroup.produit.id != item.id
      ) {
        item.type_ligne = type;
        this.currentGroup.produit = { ...item };
      }
    },

    updateTTC() {
      this.devis.groupes.push();
      this.prixTTC();
    },

    saveGroup(i) {
      this.save = i;
    },

    addGroup() {
      var groupe = {
        produits: [],
      };
      var group = {
        room: {
          lib: null,
        },
        work: null,
      };
      this.group.push(group);
      this.devis.groupes.push(groupe);
      this.showGroup[this.showGroup.length - 1].bool = false;
      this.showGroup.push({
        bool: true,
      });
    },

    addJoker() {
      var groupe = {
        produits: [],
        joker: true,
      };
      var group = {
        room: {
          lib: "Divers",
        },
        work: null,
      };
      this.group.push(group);
      this.devis.groupes.push(groupe);
      this.showGroup[this.showGroup.length - 1].bool = false;
      this.showGroup.push({
        bool: true,
      });
    },

    addpiece() {
      this.addGroup();
      this.openpiece(this.group.length - 1);
    },
    openmodelpart() {
      this.$refs["success"].hide();
      var valid = true;
      var nbgroup = this.devis.groupes.length;
      for (let i = 0; i < nbgroup; i++) {
        if (this.devis.groupes[i].produits.length == 0) {
          this.messages = "Veuillez ajouter un article";
          this.showErrorAlert = true;
          valid = false;
          break;
        }
      }
      if (!this.form2.id) {
        this.messages = "Veuillez ajouter un client";
        this.showErrorAlert = true;
        valid = false;
      }
      if (valid) {
        this.$refs["part2facture"].show();
      }
    },

    reponsequestion() {
      this.hideModal("part2facture");
      this.$refs["success"].show();
    },
    genererDevis(brouillon) {
      this.devis.modele_id = 14;
      if (brouillon) this.devis.brouillon = 1;
      if (this.isCommercialBatigo) this.devis.entreprise_id = this.form.id;
      this.devis.client_id = this.form2.id;
      this.devis.groupes.forEach((groupe) => {
        if (!groupe.piece) {
          groupe.piece = "Divers";
        }
        groupe.produits.forEach((produit) => {
          produit.produit_id = produit.id;
        });
      });

      this.devis.user_id = this.getOnlineUser.id;
      if (this.isAssistant) {
        this.devis.user_id = this.devis.owner.id;
      }
      this.createDevis();
    },

    async createDevis() {
      this.quit = true;
      const data = {
        entreprise_id: this.form.id,
        client_id: this.form2.id,
        modele_id: 14,
        debut_devis: this.devis.debut_devis,
        fin_devis: this.devis.fin_devis,
        groupes: this.devis.groupes,
      };
      if (this.devis.brouillon) {
        this.loading = true;
        data.brouillon = 1;
      }
      if (this.devis.bareme_mpr) {
        data.bareme_mpr = this.devis.bareme_mpr;
      }
      if (this.devis.conditions_reglements) {
        data.conditions_reglements = this.devis.conditions_reglements;
      }
      if (this.devis.interlocuteur) {
        data.interlocuteur_id = this.devis.interlocuteur.id;
      }
      if (this.devis.description) {
        data.description = this.devis.description;
      }
      if (this.devis.visite_technique) {
        data.visite_technique = this.devis.visite_technique;
      }
      if (this.remise == "remise") {
        data.remise_mode = "par_ligne";
        data.remise_value = this.devis.remise_value;
        data.remise_type = this.devis.remise_type;

        // data.groupes.forEach((groupe) => {
        //   groupe.produits.forEach((product) => {
        //     if (this.devis.remiseType == "remise.HT") {
        //       product.remise_type = "remise.HT";
        //       product.remise_total = product.remise_value;
        //     } else {
        //       product.remise_type = "remise.pourcentage";
        //       product.remise_total =
        //         (product.remise_value * this.totalHT(product)) / 100;
        //     }
        //   });
        // });
      } else {
        data.remise_mode = "global";
        data.remise_value = this.devis.remise_value;
        data.remise_type = this.devis.remise_type;
        if (this.devis.remise_type == "remise.HT")
          data.remise_total = this.devis.remise_value;
        else
          data.remise_total = (this.devis.remise_value * this.prixHT()) / 100;
      }

      if (this.devis.remarque) {
        data.remarque = this.devis.remarque;
      }
      if (this.devis.moyens_paiement) {
        data.moyens_paiement = this.devis.moyens_paiement;
      }
      if (this.devis.prime) {
        data.prime = this.devis.prime;
      }
      if (this.devis.organisme_preteur) {
        data.organisme_preteur = this.devis.organisme_preteur;
      }
      if (this.devis.montant_credit) {
        data.montant_credit = this.devis.montant_credit;
      }
      if (this.devis.taux_nominal) {
        data.taux_nominal = this.devis.taux_nominal;
      }
      if (this.devis.mensualite) {
        data.mensualite = this.devis.mensualite;
      }
      if (this.devis.nombre_mensualites) {
        data.nombre_mensualites = this.devis.nombre_mensualites;
      }
      if (this.devis.taeg) {
        data.taeg = this.devis.taeg;
      }
      if (this.devis.cout_total_credit) {
        data.cout_total_credit = this.devis.cout_total_credit;
      }
      if (this.devis.assurance) {
        data.assurance = this.devis.assurance;
      }
      if (this.devis.assurance_type) {
        data.assurance_type = this.devis.assurance_type;
      }
      if (this.devis.with_aide) {
        data.with_aide = this.devis.with_aide;
      }
      if (
        this.devis.montant_maprimrenov ||
        0 + this.devis.montant_maprimrenov === 0
      ) {
        data.montant_maprimrenov = this.devis.montant_maprimrenov;
      }
      if (this.devis.montant_cee || 0 + this.devis.montant_cee === 0) {
        data.montant_cee = this.devis.montant_cee;
      }
      if (this.devis.delegatairecopie) {
        data.delegataire_id = this.devis.delegatairecopie.id;
      }

      if (this.devis.same_travaux_address) {
        data.same_travaux_address = 1;
        data.rue_travaux = this.form2.rue;
        data.cp_travaux = this.form2.cp;
        data.ville_travaux = this.form2.ville;
      } else {
        data.same_travaux_address = 0;
        if (this.devis.rue_travaux) {
          data.rue_travaux = this.devis.rue_travaux;
        }
        if (this.devis.cp_travaux) {
          data.cp_travaux = this.devis.cp_travaux;
        }
        if (this.devis.ville_travaux) {
          data.ville_travaux = this.devis.ville_travaux;
        }
      }

      await this.store_devis(data)
        .then((response) => {
          this.loading = false;
          this.messages = response.message;
          this.showSuccessAlert = true;
          this.quit = true;
          this.resetclient();
          this.resetdevis();
          this.$router.push("/devis/list?id=" + response.data.devis.id);
        })
        .catch((err) => {
          this.quit = false;
          this.messages = err;
          this.showErrorAlert = true;
          this.showSuccessAlert = false;
          this.loading = false;
        });
    },
  },

  computed: {
    ...mapGetters([
      "getAllDelegataire",
      "getDelegataireLoading",
      "getAllmainoeuvres",
      "getmainoeuvreLoading",
      "getAllforfaits",
      "getforfaitLoading",
      "getTabs",
      "getAllpiece",
      "getLoadingpiece",
      "getAlltravaux",
      "getLoadingtravaux",
      "getAllProducts",
      "getProductLoading",
      "getAllclients",
      "getclientLoading",
      "getAllUsers",
      "getOnlineUserLoading",
      "getOnlineUser",
      "getentreprise",
      "getdevis",
      "getAllentreprises",
      "getentrepriseLoading",
      "getPays",
      "getdevisLoading",
      "getclient",
      "getAllsousTraitant",
      "getLoadingsousTraitant",
    ]),
    computedAllentreprises() {
      var list = [
        {
          lib: "TOUTES LES ENTREPRISES",
          id: null,
        },
      ];
      if (this.getAllentreprises)
        this.getAllentreprises.forEach((element) => {
          if (element.id != 1) list.push(element);
        });
      return list;
    },
    // tvafunction
    TVA() {
      var tva = {};
      this.devis.groupes.forEach((groupe) => {
        groupe.produits.forEach((product) => {
          let ht = 0;
          ht += this.totalAR(product);

          if (this.remise != "remise") {
            if (this.devis.remise_type == "remise.HT") {
              ht -=
                (ht * ((this.devis.remise_value * 100) / this.prixHT())) / 100;
            } else {
              ht -= (this.devis.remise_value * ht) / 100;
            }
          }

          if (tva[product.tva]) {
            tva[product.tva] += (ht * product.tva) / 100;
          } else {
            tva[product.tva] = (ht * product.tva) / 100;
          }
        });
      });
      return Object.keys(tva).map(function (key) {
        return {
          tva: key,
          value: Math.round(tva[key] * 100) / 100,
        };
      });
    },

    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },
    isCommercialBatigo() {
      return this.getOnlineUser.role == "user.commercial-batigo";
    },
    isAssistant() {
      return this.getOnlineUser.role == "user.assistant";
    },
    DiverExist() {
      var find = this.group.findIndex((item) => item.room.lib == "Divers");

      if (find < 0) return false;
      else return true;
    },
  },

  filters: {
    financial: function (value) {
      return formatMoney(value, {
        symbol: "€",
        thousand: " ",
        precision: 2,
        decimal: ",",
        format: "%v %s",
      });
    },
  },

  // beforeRouteLeave(to, from, next) {
  //   if (!this.quit) {
  //     var answer = window.confirm(this.$t("LOST_WORK"));
  //     if (answer) next();
  //     else return;
  //   }
  //   next();
  // },

  mounted() {
    if (this.$route.query.client) {
      this.client(this.$route.query.client).then(() => {
        this.form2 = this.getclient;
        this.entreprise(this.getclient.entreprise).then(() => {
          this.form = { ...this.getentreprise };
        });
      });
    }

    if (!this.isCommercialBatigo) {
      this.form = { ...this.getOnlineUser.entreprise };
    }

    if (this.isCommercialBatigo || this.isSuperAdmin) {
      this.all_entreprises({
        page: this.page,
        per_page: 999,
      });
    }
    if (this.isAssistant)
      this.all_users({
        page: this.page,
        per_page: this.per_page,
      });

    var today = new Date();
    var tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    this.devis.debut_devis = today.toLocaleDateString("fr-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    this.devis.fin_devis = tomorrow.toLocaleDateString("fr-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    this.devis.visite_technique = this.devis.debut_devis;
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  h1 {
    width: 100%;
  }
}

.body {
  @media only screen and (max-width: 900px) {
    padding: 8px;
    width: 100%;
  }
  width: 97%;
  margin: 0 auto;
  background-color: #fff;
  padding: 24px 42px;
  border-radius: 5px;

  .containerz {
    @media only screen and (max-width: 900px) {
      padding: 8px;
      width: 100%;
      margin: 0;
    }
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 2px 6px 0px rgba(229, 229, 229, 0.75);
    padding: 42px;
  }
}

.groupe_pvw {
  margin: 24px 0;
  border: 1px dotted #28367a;
  width: 100%;
  padding: 4px 8px;
  font-size: 14px;
  color: #28367a;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #28367a;
    color: #fff;
  }
}

.form {
  @media only screen and (max-width: 900px) {
    align-items: center;
  }
  display: flex;
  justify-content: space-between;

  .left,
  .right {
    width: 50%;
  }

  .logo {
    @media only screen and (max-width: 900px) {
      width: 85px;
      height: 80px;
    }
    width: 105px;
    height: 100px;
    background-color: #28367a;
    margin-bottom: 8px;
    border-radius: 10px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    font-size: 24px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .form-group {
      @media only screen and (max-width: 900px) {
        width: 95%;
      }
      margin-bottom: 6px;
      width: 50%;
      color: #28367a;
      font-size: 12px;

      input {
        padding: 6px 8px;
        color: #0f0f0f;
        font-size: 12px;
        border: 1px solid #b5b5b5;
        border-radius: 5px;
      }
    }

    hr {
      width: 50%;
      height: 1px;
      color: #707070;
      margin-top: 0.7rem;
      margin-bottom: 0.7rem;
    }
  }
  .textarea {
    margin-right: 17px;
  }

  .fieldset {
    @media only screen and (max-width: 900px) {
      width: 95%;
      margin-bottom: 8px;
    }
    width: 50%;
    margin-bottom: 6px;

    .legend {
      font-size: 12px;
      color: #28367a;
      margin-bottom: 4px;
    }

    .input,
    textarea {
      padding: 6px 8px;
      color: #0f0f0f;
      font-size: 12px;
      border: 1px solid #b5b5b5;
      border-radius: 5px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .placeholder {
      @media only screen and (max-width: 900px) {
        font-size: 10px;
      }
      color: #b5b5b5;
      font-size: 11px;
    }
  }

  .justify {
    @media only screen and (max-width: 900px) {
      width: 98%;
      margin-bottom: 8px;
    }
    width: 50%;
    display: flex;
    justify-content: space-between;
    .fieldset {
      @media only screen and (max-width: 900px) {
        width: 45%;
      }
      width: 48%;
    }
  }
}

.input-group {
  margin-bottom: 8px;
  .icon-search {
    position: absolute;
    left: 15px;
    top: 10px;
  }

  input {
    padding: 0 32px;
  }
}

.select {
  margin-bottom: 15px;
  border: 1px solid #707070;
  list-style: none;

  width: 100%;
  & li {
    padding: 6px 0;
    cursor: pointer;
    background-color: #f8f8f8;
    color: #000;
    border: none;
    box-shadow: none;
    transition: all 0.2s ease;
    text-align: center;

    &:hover {
      background-color: #28367a;
      color: #fff;
    }
  }

  .bg-danger {
    color: #fff;
  }
}

.groupe {
  position: relative;
  border: 1px dotted #707070;
  border-top: 0;
  margin: 24px 0;

  .piece {
    width: 100%;
    background-color: #28367a;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 8px;
    border-radius: 4px;

    .type {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    p {
      margin: 0;
      font-size: 14px;
      margin-right: 8px;
    }

    .option {
      @media only screen and (max-width: 900px) {
        width: 20%;
      }
      display: flex;
      justify-content: space-between;
      width: 5%;
    }

    button {
      width: 24px;
      height: 24px;
      color: #fff;
      background-color: #1b2765;
      border: 0;
      outline: 0;
      border-color: #1b2765;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-shadow: none !important;
    }
  }

  .corps {
    padding: 8px;
  }

  .travaux {
    width: 100%;
    padding: 4px 8px;
    font-size: 14px;
    color: #fff;
    background-color: #34c38f;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.complement {
  @media only screen and (max-width: 900px) {
    display: block;
  }
  margin-top: 8px;
  display: flex;
  justify-content: space-evenly;
  // justify-content: space-between;
  .block {
    @media only screen and (max-width: 900px) {
      width: 100%;
    }
    width: 30%;
  }
  .form-group {
    @media only screen and (max-width: 900px) {
      font-size: 10px;
    }
    cursor: pointer;
    font-size: 13px;
    width: 100%;
    margin-bottom: 4px;
    color: #28367a;

    .custom-select {
      padding: 6px 8px;
      color: #0f0f0f;
      font-size: 12px;
      border: 1px solid #b5b5b5;
      border-radius: 5px;
    }

    .input {
      width: 100%;
      padding: 6px 8px;
      color: #0f0f0f;
      font-size: 12px;
      border: 1px solid #b5b5b5;
      border-radius: 5px;
    }

    .placeholder {
      @media only screen and (max-width: 900px) {
        font-size: 10px;
      }
      color: #b5b5b5;
      font-size: 11px;
    }
  }
}

.table {
  margin-top: 8px;
  overflow: auto;

  button {
    @media only screen and (max-width: 900px) {
      font-size: 10px;
    }
    background-color: #28367a !important;
    border-radius: 5px;
    height: 38px;
    display: flex;
    align-items: center;
    border: none;
  }

  table {
    margin-bottom: 15px;
    width: 100%;
  }

  thead {
    background-color: #465081;
    color: #fff;
    font-weight: 800;
    text-align: center;
    font-size: 12px;

    td {
      min-width: 100px;
      border: 1px solid #fff;
      vertical-align: middle !important;
      padding: 4px 8px !important;
    }
  }

  td {
    min-width: 100px;
    border: 1px solid #fff;
    vertical-align: middle !important;
    padding: 2px 2px;
    margin: 0 !important;
  }

  tbody tr {
    height: 0;
    background-color: #fff;

    input {
      color: #323232;
      font-size: 11px;
      border-color: #707070;
      margin-bottom: 2px;
    }

    .detail_pvw {
      background-color: rgba(#000, 0.1);
      border-radius: 5px;
      width: 100%;
      height: 30px;
    }

    .add {
      width: 100%;
      height: 32px;
      border: 1px solid #e4261b;
      background-color: #e4261b;
      border-radius: 5px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      .icons {
        font-size: 14px;
      }
    }
  }

  tbody tr:hover {
    cursor: pointer;

    & .action_col {
      opacity: 1;
      color: #28367a;
      background-color: #fff;
      svg {
        &:hover {
          color: red;
        }
      }
    }
  }
  .action_col {
    @media only screen and (max-width: 900px) {
      opacity: 1;
    }
    display: flex;
    align-items: center;
    justify-content: space-around;
    opacity: 0;

    color: #28367a;
    height: 64px;
  }
}

.sousTraitant {
  span {
    cursor: pointer;
    color: #e4261b;
  }
  color: #2167d9;
}
.subtable {
  display: flex;
  justify-content: flex-end;
  width: 80%;

  .complet {
    @media only screen and (max-width: 900px) {
      margin-right: 10%;
      width: 100%;
    }
    margin-right: 10%;
    width: 100%;
    text-align: center;
    font-size: 12px;
    font-weight: bold;

    .thead {
      @media only screen and (max-width: 900px) {
        padding: 6px !important;
      }
      padding: 12px !important;
      background-color: #465081;
      color: #fff;
      width: 40%;
    }
    td {
      color: #000;
      background-color: #f8f8f8;
    }
  }
}
.radio label.custom-control-label {
  white-space: nowrap;
  font-size: smaller;
}
.textstyle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.submit {
  button {
    @media only screen and (max-width: 900px) {
      width: 145px;
      font-size: 14px;
    }
    position: relative;
    height: 48px;
    width: 40%;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 800;
    color: #fff;
  }
}

.address {
  margin-bottom: 0;
  color: #28367a;
  font-size: 12px;
}

.remise_block {
  display: flex;

  input {
    text-align: center;
  }

  select {
    width: 72px;
    padding: 4px;
  }
}

.select {
  // background: #465081;
  color: #465081;
  padding: 0;
  font-size: 12px;
  text-align: center;
  margin: 0;
}
</style>
